<template>
    <b-container>
		<h1>Andmete import rahakool.ee kontolt</h1>
		<b-alert v-if="error" variant="danger" show>
			{{ error }}
		</b-alert>
		<template v-if="!passwordChanged">
			<h4 class="mt-5">Seadista app.rahakool.ee uus parool:</h4>
			<b-row class="justify-content-center">
				<b-col sm="50">
					<b-form @submit.prevent="renewPassword" v-if="!spinner">
						<passwords :password.sync="password"
								   :password2.sync="password2"
								   :errors.sync="passwordErrors"
						></passwords>
						<b-button @click="renewPassword"
								  variant="primary"
								  :disabled="passwordErrors"
						>
							Salvesta parool
						</b-button>
					</b-form>
				</b-col>
			</b-row>
		</template>
		<template v-if="passwordChanged && !migrationStatus && !error">
			<b-list-group v-if="!spinner">
				<b-list-group-item>
					Kasutajad :
						<div v-for="(email, user) in oldData.users">
							<b>{{ user }}</b> (<b>{{ email }}</b>)
						</div>
				</b-list-group-item>
				<b-list-group-item>
					Pereliikmed :
					<div v-for="member in oldData.members">
						<b>{{ member }}</b>
					</div>
				</b-list-group-item>
				<b-list-group-item>
					Projekte : <b>{{ Object.keys(oldData.projects).length }}</b>
				</b-list-group-item>
				<b-list-group-item>
					Kategooriaid : <b>{{ Object.keys(oldData.categories).length }}</b>
				</b-list-group-item>
				<b-list-group-item>
					Liike : <b>{{ oldData.types.length }}</b>
				</b-list-group-item>
				<b-list-group-item>
					Sissekandeid : <b>{{ oldData.transactions_count }}</b>
				</b-list-group-item>
			</b-list-group>

			<b-button v-if="!spinner" @click="abortMigration" class="mt-2 mr-2">
				Katkesta
			</b-button>
			<b-button v-if="!spinner" variant="success" @click="migrate" class="mt-2">
				Migreeri andmed
			</b-button>
		</template>

		<div v-if="spinner" class="text-center text-primary my-2">
			<b-spinner variant="primary" class="align-middle"></b-spinner>
		</div>
		<b-alert v-if="spinner && migrationStatus" variant="info" show>
			Andmete migreerimine võtab aega, palun oota... <br>
			<b-progress variant="success"
						:max="oldData.transactions_count"
						class="mt-2"
						height="1.5rem"
			>
				<b-progress-bar :value="migrationProgress"
								striped
								:animated="true"
				>
					<span><strong>{{ migrationProgress }} / {{ oldData.transactions_count }}</strong></span>
				</b-progress-bar>
			</b-progress>
		</b-alert>

		<b-button v-if="migrationStatus == 'completed'" variant="success" @click="migrationCompleted" class="mt-2">
			Vaata kandeid
		</b-button>

    </b-container>
</template>

<script>

	import _ from 'lodash';
	import Swal from 'sweetalert2';
	import axios from 'axios';
	import Passwords from '@/components/Passwords';

	export default {
		components: {
			Passwords,
		},
		data () {
			return {
				spinner: false,
				error: false,
				password: '',
				password2: '',
				passwordErrors: false,
				passwordChanged: false,
				oldData: false,
				migrationStatus: false,
				migrationProgress: 0,
				migrationInterval: false,
				messages: {},
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			loadData() {
				this.spinner = true;
				this.$store.commit('setProcess', {process: 'migrating', value: true});
				axios.get('migrate/existing_data').then(({data}) => {
					if (data == 'User already migrated') {
						this.passwordChanged = true;
						this.error = 'Selle kasutaja andmed on juba app.rahakool.ee rakendusse migreeritud!';
					} else {
						this.oldData = data;
						//this.connectEcho();
					}
					this.spinner = false;
				});
			},
			connectEcho() {
				window.Echo.private(`user.${this.$store.state.user.id}`)
					.listen('.migration', (e) => {
						this.$set(this.messages, e.message, e.progress);
					});
			},
			renewPassword () {
				this.error = false;
				this.spinner = true;
				this.$store
					.dispatch('updatePassword', {
						email: this.$store.state.user.email,
						token: this.$store.state.user.token,
						password: this.password,
						password2: this.password2,
					})
					.then(() => {
						this.spinner = false;
						this.error = false;
						this.changed = true;
						Swal.fire({
							title: 'Parool muudetud!',
							confirmButtonText: 'Jätka',
							confirmButtonColor: '#6f42c1',
							icon: 'success',
						}).then((result) => {
							if (result.isConfirmed) {
								this.passwordChanged = true;
							}
						});
					})
					.catch(err => {
						this.spinner = false;
						this.loginError = 'Viga: Sellise kasutajanime ja parooliga kasutajat ei leitud!';
					})
			},
			migrate () {
				this.spinner = true;
				this.migrationStatus = 'started';
				this.migrationInterval = setInterval(() => {
					axios.get('progress/migration/'+this.oldData.transactions_count).then(res => {
						this.migrationProgress = res.data;
					});
				}, 5000 );
				axios.get('migrate/migrate').then(({data}) => {
					this.spinner = false;
					this.migrationStatus = 'completed';
					this.migrationInterval = false;
					Swal.fire({
						title: 'Migreerimine lõpetatud!',
						confirmButtonText: 'OK',
						confirmButtonColor: '#6f42c1',
						icon: 'success',
					});
					this.$store.commit('setProcess', {process: 'migrating', value: false});
					this.$store.dispatch('loadTeamData');
				});
			},
			abortMigration () {
				this.oldData = false;
				this.$store.dispatch('logout');
			},
			migrationCompleted () {
				this.$router.push({ path: '/transactions' });
			}
		}
	}
</script>
