<template>
	<div class="text-left">
		<b-form-group label-cols="60"
					  label-cols-lg="40"
					  label="Uus parool:"
					  label-for="password">
			<b-form-input
				type="password"
				name="password"
				id="password"
				v-model="pass"
				class="mb-2"
				@update="passesChanged"
				:state="validatePassword"
			></b-form-input>
			<b-form-invalid-feedback :state="validatePassword">
				<ul>
					<li v-for="(error, eIdx) in passErrors">
						{{ error }}
					</li>
				</ul>
			</b-form-invalid-feedback>
			<b-form-valid-feedback :state="validatePassword">
				Parool ok
			</b-form-valid-feedback>
		</b-form-group>
		<b-form-group label-cols="60"
					  label-cols-lg="40"
					  label="Uus parool:"
					  label-for="password2">
			<b-form-input
				type="password"
				id="password2"
				name="password2"
				v-model="pass2"
				class="mb-2"
				@update="passesChanged"
				:state="validatePassword2"
			></b-form-input>
			<b-form-invalid-feedback :state="validatePassword2">
				Korratud parool pole sama
			</b-form-invalid-feedback>
			<b-form-valid-feedback :state="validatePassword2">
				OK
			</b-form-valid-feedback>
		</b-form-group>
	</div>
</template>

<script>

	export default {
		name: 'Passwords',
		props: ['password','password2','errors'],
		data () {
			return {
				pass: '',
				pass2: '',
				passErrors: []
			}
		},
		computed: {
			validatePassword() {
				this.passErrors = [];
				if (this.pass.length < 8) {
					this.passErrors.push('Vähemalt 8 tähemärki pikk');
				}
				const isContainsUppercase = /^(?=.*[A-Z]).*$/;
				if (!isContainsUppercase.test(this.pass)) {
					this.passErrors.push('Sisaldab suurtähti.');
				}
				const isContainsLowercase = /^(?=.*[a-z]).*$/;
				if (!isContainsLowercase.test(this.pass)) {
					this.passErrors.push('Sisaldab väiketähti.');
				}

				const isContainsNumber = /^(?=.*[0-9]).*$/;
				if (!isContainsNumber.test(this.pass)) {
					this.passErrors.push('Sisaldab numbreid.');
				}

				return !this.passErrors.length > 0;
			},
			validatePassword2() {
				return this.pass ? this.pass == this.pass2 : null;
			},
		},
		created() {
			this.passesChanged();
		},
		methods: {
			passesChanged () {
				let anyErrors = !this.validatePassword || !this.validatePassword2;
				this.$emit('update:password', this.pass);
				this.$emit('update:password2', this.pass2);
				this.$emit('update:errors', anyErrors);
			},
		},
	}
</script>
